import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        currentUser: null
    },
    getters: {
      currentUser: state => state.currentUser
    },
    mutations: {
        updateUser (state, user) {
            console.log("store::mutations::updateUser", user);
            state.currentUser = user;
        }
    },
    actions: {
        updateUser(context, user) {
            console.log("store::actions::updateUser", user);
            context.commit('updateUser', user);
        }
    }
})

export default store