import router from '../router'
import axios from "axios";

let api = axios.create({
        timeout: 30000,
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    })
;

api.interceptors.request.use(req => {
    console.log(`${req.method} ${req.url}`);
    return req;
});

api.interceptors.response.use(
    res => {
        console.log('Response', res);
        return res;
    },
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            console.log("Not authorized - Redirect to login");
            router.replace('/login');
            return Promise.reject(error.response);
        }
        throw error;
    }
);

export default api;