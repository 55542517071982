<template>
  <v-container>
    <v-row>
      <v-col class="mt-15">
          <template v-for="(item, itemIndex) in items">
            <v-card class="mx-auto" :key="itemIndex">
              <v-card-title>
                {{ item.company }}<v-spacer></v-spacer><span class="d-none d-sm-inline">{{item.identifier}}</span>
              </v-card-title>
              <v-card-subtitle>
                Geschäftsführung: {{ item.ceo }}
              </v-card-subtitle>
              <v-card-text>
                <v-container class="ma-0 pa-0">
                  <v-row class="d-block d-sm-none">
                    <v-col cols="12">
                      <v-chip small :color="item.insolvencyStatus.toLowerCase()" :dark="item.insolvencyStatus === 'Red'" class="mr-2">Insolvenz</v-chip>
                      <v-chip small :color="item.indebtnessStatus.toLowerCase()" :dark="item.indebtnessStatus === 'Red'">Überschuldung</v-chip>
                    </v-col>
                  </v-row>
                  <v-row class="d-block d-sm-none">
                    <v-col cols="6" class="my-0 py-0">Aktenzeichen</v-col>
                    <v-col cols="6" class="my-0 py-0">{{item.identifier}}</v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                    <v-col cols="6" sm="4" md="3" class="my-0 py-0">Angelegt am:</v-col>
                    <v-col cols="6" sm="4" md="5" class="my-0 py-0">{{item.created}}</v-col>
                    <v-col cols="12" sm="4" class="my-0 py-0 text-right d-sm-block d-none">
                        <v-chip small :color="item.insolvencyStatus.toLowerCase()" :dark="item.insolvencyStatus === 'Red'" class="mr-2">Insolvenz</v-chip>
                        <v-chip small :color="item.indebtnessStatus.toLowerCase()" :dark="item.indebtnessStatus === 'Red'">Überschuldung</v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="4" md="3" class="my-0 py-0">Aktualisiert am:</v-col>
                    <v-col cols="6" sm="4" md="5" class="my-0 py-0">{{item.lastUpdate}}</v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary lighten-2" text @click="pdfExport(item.identifier)">
                  Drucken
                </v-btn>
                <v-btn color="primary lighten-2" text>
                  <router-link :to="{ name: 'Beratungsanfragedetails', params: { id: item.identifier }}">Details</router-link>
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-center">
          <v-pagination
              v-if="pageInfo.numberOfPages > 1"
              v-model="pageInfo.page"
              :length="pageInfo.numberOfPages"
              :total-visible="7"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'ConsultingTableView',
  props: {},
  data: () => ({
    pageInfo: {
      page: 1,
      pageSize: 50,
      numberOfPages: 1,
      total: 0
    },
    q: null,
    showError: false,
    error: null,
    loading: false,
    items: []
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log("ConsultingTableView::created");
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      console.log("ConsultingTableView::fetchData");
      this.error = this.post = null
      this.loading = true
      let self = this;
      this.$http.get(`${process.env.VUE_APP_ENDPOINT}/admin/consulting-requests`,
          {
            page: self.pageInfo.page,
            pageSize: self.pageInfo.pageSize,
            q: self.q,
          }
      )
          .then(function (response) {
            self.items = response.data.records;
            self.pageInfo = response.data.pageInfo;
          })
          .catch(function (error) {
            if (error.response.status === 500) {
              self.error = "Da ist was schief gelaufen...";
            } else {
              self.error = error.response;
            }
            self.showError = true
          })
          .finally(() => this.loading = false)
      ;

      // replace `getPost` with your data fetching util / API wrapper
      // getPost(this.$route.params.id, (err, post) => {
      //   this.loading = false
      //   if (err) {
      //     this.error = err.toString()
      //   } else {
      //     this.post = post
      //   }
      // })
    },
    pdfExport(identifier) {
      console.log("PDF Export for " + identifier);
      window.open(`${process.env.VUE_APP_ENDPOINT}/admin/consulting-requests/details/${identifier}/export.pdf`)
    }
  }
}
</script>
