import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import api from './plugins/api'
import VueCurrencyInput from 'vue-currency-input'
import router from './router'
import store from './store'

const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'EUR', locale:'de' }
}

Vue.config.productionTip = false
Vue.use(VueCurrencyInput, pluginOptions)
Vue.prototype.$http = api;
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
