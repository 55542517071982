import Vue from 'vue'
import VueRouter from 'vue-router'

import ConsultingTableView from '../views/ConsultingTableView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginForm.vue')
  },
  {
    path: '/',
    name: 'Beratungsanfragen',
    component: ConsultingTableView
  },
  {
    path: '/consulting/details/:id',
    name: 'Beratungsanfragedetails',
    component: () => import (/* webpackChunkName: "consultingdetails" */'../views/ConsultingDetailView.vue')
  },
  {
    path: '/financial-data',
    name: 'FinancialRawData',
    component: () => import (/* webpackChunkName: "financialdata" */'../views/FinancialRawDataView.vue')
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: () => import (/* webpackChunkName: "newsletter" */'../views/NewsletterTableView.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import (/* webpackChunkName: "account" */'../views/AccountView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
